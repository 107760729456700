<template>
    <div class="checkbox-container">
        <input
            :key="id"
            type="checkbox"
            :name="name"
            :value="value"
            :id="id"
            :checked="checkedValue"
            :disabled="disabled"
            :class="{ disabled }"
            @change="changeHandler"
        />
        <label :for="id"> {{ label }} </label>
    </div>
</template>

<script>
export default {
    name: 'Checkbox',
    emits: ['update:modelValue'],
    props: {
        name: {
            type: String
        },
        label: {
            type: String,
            default: ''
        },
        value: {
            type: [Number, String],
            default: ''
        },
        modelValue: {
            type: Object,
            default: function () {
                return {}
            }
        },
        disabled: {
            type: Boolean
        },
        isCheckAll: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        changeHandler: function () {
            const modelValue = this.modelValue
            if (modelValue[this.value]) {
                modelValue[this.value] = false
            } else {
                modelValue[this.value] = true
            }

            this.$emit('update:modelValue', modelValue)

            if (this.value === 'checkAll' && event.target.checked)
                this.$setGaEvent('checkAll', 'click-Checkbox')
        }
    },
    computed: {
        id: function () {
            return `${this.name}-${this.value}`
        },
        checkedValue: function () {
            return this.modelValue[this.value]
        }
    },
    activated() {
        if (this.$route.path === '/tia/insurance') {
            document.getElementById(this?.id).checked = this.checkedValue
        }
    }
}
</script>

<style lang="scss" scoped>
input {
    &[type='checkbox'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        width: 16px;
        height: 16px;
        margin: 0;
        cursor: pointer;
        border: none;
        &:before {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            background-color: $primary-white !important;
            border: 1px solid $eighth-grey;
            border-radius: 3px;
        }
        &:after {
            content: '';
            display: block;
            width: 4px;
            height: 7px;
            margin: -14px 0 0 6px;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
        &:checked {
            &:before {
                background-color: $third-blue !important;
                border: 1px solid $third-blue;
            }
            &:after {
                border: white solid;
                border-width: 0 1px 1px 0;
            }
        }
    }
}
.checkbox-container {
    display: flex;
    align-items: center;
    label {
        color: $sixth-black;
        font-size: 14px;
        margin: 0 10px 0 10px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
    }
}
</style>
