<template>
    <transition name="fade" mode="out-in">
        <div class="input-container" :style="containerStyle">
            <div v-if="title" class="form-label">
                <span v-if="required" class="required">*</span><span></span
                >{{ title }}
            </div>
            <Textbox
                v-if="filterType === 'string'"
                name="checkboxFilter"
                type="text"
                title=""
                :placeholder="placeholder"
                v-model="filterValue"
                @click="setGaEvent('filterOption')"
            />
            <template v-else>
                <Textbox
                    name="checkboxFilterStart"
                    title="起"
                    placeholder="請輸入最小值"
                    :type="filterType"
                    v-model="filterStartValue"
                    @click="setGaEvent('filterStartOption')"
                />
                <Textbox
                    name="checkboxFilterEnd"
                    title="迄"
                    placeholder="請輸入最大值"
                    :type="filterType"
                    v-model="filterEndValue"
                    @click="setGaEvent('filterEndOption')"
                />
            </template>
            <div class="checkbox-wrapper">
                <Checkbox
                    name="checkedAll"
                    label="選取全部搜尋選項"
                    value="all"
                    v-model="checkboxAllValue"
                    @click="
                        setGaEvent(
                            `checkedAll${
                                checkboxAllValue.all ? 'False' : 'True'
                            }`
                        )
                    "
                />
                <Checkbox
                    v-for="(option, key) in filteredOptions"
                    :key="key"
                    :name="option.label"
                    :label="option.label"
                    :value="option.value"
                    v-model="checkboxValue"
                />
            </div>
            <div v-if="isError" class="error-message">{{ errorMessage }}</div>
        </div>
    </transition>
</template>

<script>
// import _ from 'lodash'
import Textbox from '@/components/Textbox.vue'
import Checkbox from '@/components/Checkbox.vue'

export default {
    name: 'CheckboxDropdown',
    emits: ['update:modelValue'],
    components: {
        Textbox,
        Checkbox
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        filterType: {
            type: String,
            default: 'string'
        },
        modelValue: {
            type: Object,
            default: function () {
                return {}
            }
        },
        required: {
            type: Boolean,
            default: false
        },
        name: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isError: {
            type: Boolean,
            default: false
        },
        errorMessage: {
            type: String,
            default: '輸入有誤'
        },
        options: {
            type: Array,
            default: function () {
                return []
            }
        },
        width: {}
    },
    methods: {
        validNumberOption: function (option) {
            let valid = true
            const value = this.$getPureNumber(option.value)
            const startValue = this.$getPureNumber(this.filterStartValue)
            const endValue = this.$getPureNumber(this.filterEndValue)
            const hasStartValue = startValue || startValue === 0
            const hasEndValue = endValue || endValue === 0
            if (hasStartValue && value < startValue) {
                valid = false
            }
            if (hasEndValue && value > endValue) {
                valid = false
            }
            return valid
        },
        validDateOption: function (option) {
            let valid = true
            const value = new Date(option.value)
            const startValue = new Date(this.filterStartValue)
            const endValue = new Date(this.filterEndValue)
            const hasInterval =
                this.isValidDate(startValue) || this.isValidDate(endValue)
            const isDate = option.value?.includes('-', 4) || false

            if (startValue && value < startValue) {
                valid = false
            }
            if (endValue && value > endValue) {
                valid = false
            }
            if (!isDate && hasInterval) {
                valid = false
            }

            return valid
        },
        validStringOption: function (option) {
            // 處理空白鍵分隔的字串
            let valid = true
            const contexts = this.filterValue.split(' ')
            contexts.forEach((chunk) => {
                if (!option.label.includes(chunk)) valid = false
            })
            return valid
        },
        setEachOptionChecked: function (isChecked) {
            const values = this.filteredOptionsValues
            values.forEach((value) => {
                this.checkboxValue[value] = isChecked
            })
        },
        setGaEvent: function (label) {
            this.$setGaEvent(label, 'filter-Tool')
        },
        isValidDate: function (val) {
            return val instanceof Date && !isNaN(val)
        }
    },
    computed: {
        containerStyle: function () {
            const style = {}
            if (typeof this.width === 'string' && this.width.includes('%')) {
                style['width'] = this.width
            } else if (typeof this.width === 'number') {
                style['width'] = `${this.width}px`
            }
            return style
        },
        filteredOptions: function () {
            return this.options.filter((option) => {
                // 數字
                if (this.filterType === 'number') {
                    return this.validNumberOption(option)
                }
                if (this.filterType === 'date') {
                    return this.validDateOption(option)
                }
                // 字串
                else return this.validStringOption(option)
            })
        },
        checkboxValue: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        },
        checkboxAllValue: {
            get() {
                const values = this.filteredOptionsValues
                const shouldChecked =
                    values.length > 0 &&
                    values.every((value) => this.checkboxValue[value])
                return { all: shouldChecked }
            },
            set(val) {
                this.setEachOptionChecked(val.all)
            }
        },
        filteredOptionsValues: function () {
            return this.filteredOptions.map((option) => option.value)
        }
    },
    data() {
        return {
            filterValue: '',
            filterStartValue: '',
            filterEndValue: ''
        }
    }
}
</script>

<style lang="scss" scoped>
.input-container {
    width: 210px;

    &:last-child {
        margin-right: 0px;
    }
}

.checkbox-wrapper {
    height: Min(30vh, 250px);
    overflow-y: auto;
    border: $fifth-black 1px solid;
    padding: 15px 10px 0px;
}

.checkbox-container {
    display: flex;
    align-items: flex-start;
    min-height: 40px;
    width: 100%;
    :deep(label) {
        margin-top: -2px;
    }
}

.form-label {
    color: $sixth-black;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;
    .required {
        color: $primary-red;
    }
}

.error-message {
    position: absolute;
    font-size: 12px;
    color: $fourth-red;
    margin-top: 5px;
}
</style>
